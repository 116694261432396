import React, { useState } from 'react';
import './Modal.css';

function AddPhrasesModal({ isOpen, onClose, onSave }) {
    const [textAreaValue, setTextAreaValue] = useState('');

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    const handleSave = () => {
        const lines = textAreaValue.split('\n').filter(line => line.trim() !== '');
        onSave(lines);
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={handleOverlayClick}>
            <div className="modal">
                <h2>Введите исходные фразы</h2>
                <textarea
                    value={textAreaValue}
                    onChange={(e) => setTextAreaValue(e.target.value)}
                    placeholder="Введите по одной фразе на каждой строке"
                ></textarea>
                <div className="modal-buttons">
                    <button className="modal-save-button" onClick={handleSave}>Сохранить</button>
                    <button className="modal-close-button" onClick={onClose}>Отмена</button>
                </div>
            </div>
        </div>
    );
}

export default AddPhrasesModal;
