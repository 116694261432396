import React, { useState } from 'react';
import AddPhrasesModal from './AddPhrasesModal';
import CleanWordsModal from './CleanWordsModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faBroom } from '@fortawesome/free-solid-svg-icons';

function SourceText({ sourceText, onAddNegativeWord, negativeWords, setSourceText }) {
    const [isAddPhrasesModalOpen, setIsAddPhrasesModalOpen] = useState(false);
    const [isCleanWordsModalOpen, setIsCleanWordsModalOpen] = useState(false);
    const [cleanMinusWords, setCleanMinusWords] = useState(true);
    const [cleanSpaces, setCleanSpaces] = useState(true);

    const splitIntoWords = (text) => {
        return text.split(' ').map((word, index) => ({
            id: `${word}-${index}`,
            word,
        }));
    };

    const handleOpenAddPhrasesModal = () => {
        setIsAddPhrasesModalOpen(true);
    };

    const handleOpenCleanWordsModal = () => {
        setIsCleanWordsModalOpen(true);
    };

    const handleSaveCleanWords = () => {
        let cleanedText = [...sourceText];

        if (cleanMinusWords) {
            cleanedText = cleanedText.map(line => line.replace(/(^|\s)-\S+/g, ''));
        }

        if (cleanSpaces) {
            cleanedText = cleanedText.map(line => line.replace(/\s+/g, ' ').trim());
        }

        setSourceText(cleanedText);
        setIsCleanWordsModalOpen(false);
    };

    const handleSavePhrases = (lines) => {
        setSourceText(lines);
    };

    return (
        <div className="source-text panel">
            <div className="header">
                <h2>Исходный текст</h2>
                <div>
                    <button className="icon-button" onClick={handleOpenAddPhrasesModal} title="Добавить фразы">
                        <FontAwesomeIcon icon={faPlus} />
                    </button>
                    <button className="icon-button" onClick={handleOpenCleanWordsModal} title="Очистить слова">
                        <FontAwesomeIcon icon={faBroom} />
                    </button>
                </div>
            </div>
            {sourceText.map((line, lineIndex) => (
                <div key={lineIndex} className="line">
                    {splitIntoWords(line).map(({ id, word }) => (
                        <span
                            key={id}
                            className={`word ${negativeWords.includes(word.toLowerCase()) ? 'highlight' : ''}`}
                            onClick={() => onAddNegativeWord(word)}
                        >
              {word}
            </span>
                    ))}
                </div>
            ))}
            <AddPhrasesModal
                isOpen={isAddPhrasesModalOpen}
                onClose={() => setIsAddPhrasesModalOpen(false)}
                onSave={handleSavePhrases}
            />
            <CleanWordsModal
                isOpen={isCleanWordsModalOpen}
                onClose={() => setIsCleanWordsModalOpen(false)}
                onSave={handleSaveCleanWords}
                cleanMinusWords={cleanMinusWords}
                setCleanMinusWords={setCleanMinusWords}
                cleanSpaces={cleanSpaces}
                setCleanSpaces={setCleanSpaces}
            />
        </div>
    );
}

export default SourceText;
