import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import './Modal.css';

function CleanWordsModal({ isOpen, onClose, onSave, cleanMinusWords, setCleanMinusWords, cleanSpaces, setCleanSpaces }) {
    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={handleOverlayClick}>
            <div className="modal">
                <h2>Настройки очистки</h2>
                <div className="modal-options">
                    <label className="checkbox-label">
                        <input
                            type="checkbox"
                            checked={cleanMinusWords}
                            onChange={(e) => setCleanMinusWords(e.target.checked)}
                        />
                        <span className="custom-checkbox"></span>
                        Очистка минус слов
                        <span className="info-icon">
              <FontAwesomeIcon icon={faInfoCircle} title="Удаляет слова, начинающиеся с '-'" />
            </span>
                    </label>
                    <label className="checkbox-label">
                        <input
                            type="checkbox"
                            checked={cleanSpaces}
                            onChange={(e) => setCleanSpaces(e.target.checked)}
                        />
                        <span className="custom-checkbox"></span>
                        Очистка пробелов
                        <span className="info-icon">
              <FontAwesomeIcon icon={faInfoCircle} title="Удаляет пробелы в начале, конце и дублирующиеся пробелы" />
            </span>
                    </label>
                </div>
                <div className="modal-buttons">
                    <button className="modal-save-button" onClick={onSave}>Сохранить</button>
                    <button className="modal-close-button" onClick={onClose}>Отмена</button>
                </div>
            </div>
        </div>
    );
}

export default CleanWordsModal;
