import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

function ResultWords({ resultWords }) {
    const handleCopyToClipboard = () => {
        const textToCopy = resultWords.join('\n');
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                alert('Рабочие фразы скопированы в буфер обмена!');
            })
            .catch(err => {
                console.error('Не удалось скопировать текст: ', err);
            });
    };

    return (
        <div className="result-words panel">
            <div className="header">
                <h2>Рабочие слова</h2>
                <button className="icon-button" onClick={handleCopyToClipboard} title="Скопировать">
                    <FontAwesomeIcon icon={faCopy} />
                </button>
            </div>
            <ul>
                {resultWords.map((word, index) => (
                    <li key={index}>{word}</li>
                ))}
            </ul>
        </div>
    );
}

export default ResultWords;
