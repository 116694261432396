import React, { useState, useEffect } from 'react';
import SourceText from './SourceText';
import NegativeWords from './NegativeWords';
import ResultWords from './ResultWords';
import './App.css';
import './Modal.css';

function App() {
  const [sourceText, setSourceText] = useState([]);
  const [negativeWords, setNegativeWords] = useState([]);
  const [resultWords, setResultWords] = useState([]);

  const handleAddNegativeWord = (word) => {
    if (!negativeWords.includes(word.toLowerCase())) {
      setNegativeWords([...negativeWords, word.toLowerCase()]);
    }
  };

  const handleRemoveNegativeWord = (word) => {
    setNegativeWords(negativeWords.filter(w => w !== word.toLowerCase()));
  };

  useEffect(() => {
    const updatedResultWords = sourceText.filter(line =>
        !negativeWords.some(word => new RegExp(`(^|\\s)${word}(?=\\s|$)`, 'gi').test(line))
    );
    setResultWords(updatedResultWords);
  }, [negativeWords, sourceText]);

  return (
      <div className="App">
        <div className="panels">
          <SourceText
              sourceText={sourceText}
              onAddNegativeWord={handleAddNegativeWord}
              negativeWords={negativeWords}
              setSourceText={setSourceText}
          />
          <NegativeWords
              negativeWords={negativeWords}
              onRemoveNegativeWord={handleRemoveNegativeWord}
          />
          <ResultWords resultWords={resultWords} />
        </div>
      </div>
  );
}

export default App;
