import React from 'react';

function NegativeWords({ negativeWords, onRemoveNegativeWord }) {
    return (
        <div className="negative-words panel">
            <div className="header">
                <h2>Минус-слова</h2>
            </div>
            <ul>
                {negativeWords.map((word, index) => (
                    <li key={index} className="negative-word" onClick={() => onRemoveNegativeWord(word)}>
                        {word}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default NegativeWords;
